import React from 'react';
import ErrorBoundary from '@dnb/error-boundary';
import { H1, P } from '@dnb/eufemia';
import Head from '@dnb/helmet';
import CrashingComponent from '../../components/CrashingComponent';
import WorkingComponent from '../../components/WorkingComponent';

function PageIndex() {
  return (
    <>
      <Head>
        <title>Page Title</title>
      </Head>

      <H1 bottom="1rem" top="3rem">
        ErrorBoundary
      </H1>
      <P bottom="2rem">
        This page demonstrates failing code in production and how you can use
        ErrorBoundary to catch them.
      </P>

      <ErrorBoundary>
        <WorkingComponent />
      </ErrorBoundary>
      <ErrorBoundary>
        <CrashingComponent />
      </ErrorBoundary>
    </>
  );
}

export default PageIndex;
